import * as moduleAuth from './auth'
import * as moduleTime from './time'

export default (() => {
  const modules = {
    auth: moduleAuth,
    time: moduleTime,
  }
  for (const moduleName of Object.keys(modules)) modules[moduleName] = { namespaced: true, ...modules[moduleName] }
  return modules
})()
