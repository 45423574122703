import VuexPersistence from 'vuex-persist'
import createCache from 'vuex-cache'
import { createStore } from 'vuex'
import localForage from 'localforage'

import modules from './modules'

export const vuexPersist = new VuexPersistence({
  // strictMode: true, // This **MUST** be set to true
  // storage: window.localStorage,
  storage: localForage,
  // modules: ['time'], //only save user module
  // reducer: (state) => ({
  //   // articles: state['articles'],
  //   // youtubePlaylists: state['youtubePlaylists'],
  //   // fitogramEvents: state['fitogramEvents'],
  // }),
  filter(mutation) {
    // console.log('mutation', mutation)
    if (mutation.type.startsWith('time/')) return false
    // if (mutation.type.startsWith('device/')) return false
    // if (mutation.type.startsWith('auth/')) return false
    // if (mutation.type.startsWith('youtubePlaylists/')) return true
    return false // change to true if you want to use storage
  },
  asyncStorage: true,
  // supportCircular: true,
})

export const store = createStore({
  modules,
  // state: {},
  // mutations: {},
  // actions: {},
  // getters: {},
  mutations: {
    // only in strict mode, but strict mode persist is broken?
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION, // this mutation **MUST** be named "RESTORE_MUTATION"
  },
  strict: false, // TODO: make this not true in production
  plugins: [createCache({ timeout: 60 * 60 }), vuexPersist.plugin],
})

export default store
