import { onAuthStateChanged, signOut, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { getDoc, doc, runTransaction } from 'firebase/firestore'
import AwaitLock from 'await-lock'
import { isBefore } from 'date-fns'

import { firebaseAuth, db } from '@/firebase'
import { actionCodeSettings } from '@/firebase-action-code-settings'
import { pnotice } from '@/pnotice'

const lock = new AwaitLock()

export const state = {
  userProvider: null,
  userCurrent: null,
  accountCurrent: null,
  accountPrimary: null,
  accountIsEnabled: false,
  accountIsPaid: false,
}

export const mutations = {
  LOGOUT(state) {
    state.userProvider = null
    state.userCurrent = null
    state.accountCurrent = null
    state.accountPrimary = null
  },
  SET_USER_PROVIDER(state, data) {
    // state.userProvider = data
  },
  SET_USER_CURRENT(state, data) {
    state.userCurrent = data
  },
  SET_ACCOUNT_CURRENT(state, data) {
    state.accountCurrent = data
    state.accountIsEnabled = isBefore(data.data().enabledAt?.seconds * 1000, Date.now())
    state.accountIsPaid = isBefore(data.data().paidAt?.seconds * 1000, Date.now()) || !!data.data().roles?.includes('goldcard')
    state.accountIsSuper = !!data.data().roles?.includes('superaccount')
    console.log(state);
  },
  SET_ACCOUNT_PRIMARY(state, data) {
    state.accountPrimary = data
  },
}

export const actions = {
  async init({ commit, dispatch }) {},
  restore({ dispatch, state }) {
    console.log('auth:restore: dispatched')
    return new Promise((resolve) => {
      onAuthStateChanged(firebaseAuth, async (userProvider) => {
        if (userProvider?.uid === state.userCurrent?.id) {
          // User is logged in already and no need to reload all the info again.
          return resolve(userProvider)
        }

        if (userProvider) {
          // User logging in or registering, so send it to do that.
          await dispatch('registerOrLogin', userProvider)
          return resolve(userProvider)
        }

        // No user login info so send for logout to make sure things are cleaned.
        await dispatch('logout')
        return resolve(null)
      })
    })
  },

  async registerOrLogin({ commit, state }, userProvider) {
    // TODO: Must make this locking because it tends to create multiple accounts…
    console.log('auth:registerOrLogin started with', userProvider)
    await lock.acquireAsync()
    console.log('inside lock')
    if (state.userCurrent && state.accountCurrent) {
      console.log('already logged in exit lock')
      return lock.release()
    }

    try {
      const { uid } = userProvider

      const userDocUpdate = {
        email: userProvider.email,
        createdAt: new Date(userProvider.metadata.creationTime),
        lastLoginAt: new Date(userProvider.metadata.lastSignInTime),
        provider: userProvider.providerId,
      }

      const userRef = doc(db, 'User', uid)
      const accountRef = doc(db, 'Account', uid)

      // Get the dbUser while also making sure it exists and that the user also has an account when it's first created!
      await runTransaction(db, async (transaction) => {
        console.log('transaction started')
        const dbUser = await getDoc(userRef)
        if (!dbUser.exists()) {
          // if the user does not exist, create new account as well…
          await transaction.set(accountRef, {
            createdAt: new Date(),
            updatedAt: new Date(),
            refUserOwner: userRef,
          })
          // Set the newly created accountRef (based on the idUser) for the user document to be updated/created.
          userDocUpdate.refAccountPrimary = accountRef
        }

        // Update user if exist, set (aka create) if it does not.
        if (dbUser.exists()) {
          await transaction.update(userRef, userDocUpdate)
          pnotice(`${userDocUpdate.email}: Login`, { silent: true })
        } else {
          transaction.set(userRef, userDocUpdate)
          pnotice(`${userDocUpdate.email}: Registration`)
        }
      })

      // Read the user & account from the database to make sure it's up to date.
      const dbUser = await getDoc(userRef)
      const dbAccount = await getDoc(dbUser.data().refAccountPrimary)
      console.log('transaction successfully finished')

      // Dispatch User Login / User Present
      // commit('SET_USER_PROVIDER', userProvider)
      commit('SET_USER_CURRENT', dbUser)
      commit('SET_ACCOUNT_CURRENT', dbAccount)
      commit('SET_ACCOUNT_PRIMARY', dbAccount)
      console.log('auth:restore finished with all things settled')
    } catch (error) {
      console.log('error while processing loginOrRegister', error)
    } finally {
      console.log('finally lock released')
      lock.release()
    }
  },

  sendPasswordlessEmail({ commit }, { email }) {
    return new Promise((resolve, reject) => {
      sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email)
          return resolve(true)
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          alert('Error while sending auth email. Check console!')
          console.log('Error while sending the auth email:', errorCode, errorMessage, error)
          return resolve(false)
        })
    })
  },
  logout({ commit }) {
    signOut(firebaseAuth)
      .then(() => {
      // TODO: add toast confirm logout
        console.log('user logout successful')
        commit('LOGOUT', null)
      })
      .catch((error) => {
        console.log('error while user logout', error)
        commit('LOGOUT', null)
      })
      .finally(async () => {
        const router = await import('@/router')
        await router.default.push(`/auth`)
      })
  },
  checkLink({ commit, state }) {
    // if userProvider is set, the user is already logged in and should be treated as such.
    if (state.userProvider) return 'login-successful'
    return new Promise((resolve, reject) => {
      // Confirm the link is a sign-in with email link.
      if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
        commit('LOGOUT', null)
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem('emailForSignIn')
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation')
        }

        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(firebaseAuth, email, window.location.href)
          .then(async (result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn')
            // You can access the new user via result.user
            // TODO: add toast to let user know they are logged in and with which email
            console.log('login successful', result)
            return resolve('login-successful')
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            commit('LOGOUT', null)
            console.log('error while checking the sign in link:', error)
            return resolve('error-while-checking-login-link')
          })
      } else {
        return resolve('no-login-link')
      }
    })
  },
}
