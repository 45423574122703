import { firebaseConfig } from '@/firebase'

export const config = {
  'foxi-link-development': {
    isProduction: false,
    env: firebaseConfig.projectId,
    clearCacheTarget: 'https://foxi-link-development-catch.web.app',
    pushnotice: {
      "idChat": -535404892,
      "secret": "987c9e4e3b4b7d57139e8a3d335e68d55a3150a8",
      "level": "info",
      "env": "production",
      "namespace": "appname:router",
      "silent": false,
      "text": "Your Message",
    },
  },
  'foxi-link-production': {
    isProduction: true,
    env: firebaseConfig.projectId,
    clearCacheTarget: 'https://foxi-link-catch-short.web.app',
    pushnotice: {
      "idChat": -535404892,
      "secret": "987c9e4e3b4b7d57139e8a3d335e68d55a3150a8",
      "level": "info",
      "env": "production",
      "namespace": "appname:router",
      "silent": false,
      "text": "Your Message",
    },
  }
}

export const configEnv = config[firebaseConfig.projectId]
