import { configEnv } from '@/config'
import pushnotice from 'pushnotice'

export const pnotice = pushnotice('App', {
  debug: !configEnv.isProduction,
  env: configEnv.env.replace(/\W/g, '').substr(0, 19),
  chat: {
    id: configEnv.pushnotice.idChat,
    secret: configEnv.pushnotice.secret,
  },
})

export default pnotice
