import { createRouter, createWebHistory } from '@ionic/vue-router'
import { store } from '@/store'

const routes = [
  {
    name: 'auth',
    path: '/auth',
    component: () => import('@/views/Authenticate.vue'),
  },
  {
    name: 'logout',
    path: '/logout',
    component: () => import('@/views/Authenticate.vue'),
  },
  // {
  //   name: 'dashboard',
  //   path: '/dashboard',
  //   component: () => import('@/views/Dashboard.vue'),
  // },
  {
    name: 'support',
    path: '/support',
    component: () => import('@/views/Support.vue'),
  },
  {
    path: '/help',
    redirect: { name: 'support' },
  },
  {
    name: 'pricing',
    path: '/pricing',
    component: () => import('@/views/Pricing.vue'),
  },
  {
    path: '/plans',
    redirect: { name: 'pricing' },
  },
  {
    name: 'billing',
    path: '/billing',
    component: () => import('@/views/Billing.vue'),
  },
  {
    name: 'shorts',
    path: '/shorts',
    component: () => import('@/views/ShortList.vue'),
  },
  {
    path: '/short',
    redirect: { name: 'shorts' },
  },
  {
    name: 'shortNew',
    path: '/shorts/new',
    component: () => import('@/views/ShortNew.vue'),
  },
  {
    name: 'shortSingle',
    path: '/short/:id',
    component: () => import('@/views/ShortSingle.vue'),
  },
  {
    name: 'settings',
    path: '/settings',
    component: () => import('@/views/Settings.vue'),
  },
  {
    path: '',
    redirect: { name: 'shorts' },
  },
  // {
  //   path: '',
  //   redirect: '/folder/Inbox',
  // },
  // {
  //   path: '/folder/:id',
  //   component: () => import('@/views/FolderPage.vue'),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored // needs to be called to make sure the store is actually restored!
  return next()
}

const waitForAuthCheck = async (to, from, next) => {
  await store.dispatch('auth/restore')
  if (to.name !== 'auth' && (!store.state.auth.userCurrent || !store.state.auth.accountCurrent || !store.state.auth.accountIsEnabled)) {
    console.log('no user logged in')
    return next({ name: 'auth' })
  }

  return next()
}

router.beforeEach(waitForStorageToBeReady)
router.beforeEach(waitForAuthCheck)

export default router
